<script setup lang="ts">
import classes from "./classes"
import { ref, computed, PropType, watch, onBeforeMount, onMounted } from "vue"
import { Device } from "shared/api"
import { concat, isArray } from "lodash"
import { MagnifierImage, Button } from "shared/ui"
import { ToggleFavouriteDevice } from "features/toggle-favourite-device"
import { ToggleComparableDevice } from "features/toggle-comparable-device"
import { DeviceMedia, YANDEX_METRIKA_GOALS, emitMixpanelEventCardVideoPlayed, emitYandexMetrika, linkStyles, linkUnderlineStyles, skeletonStyles } from "shared/lib"
import { useBreakpoints } from "@vueuse/core"
import { CatalogDeviceMediaGalleryMobileSlider, Stoppers } from "widgets"

// use vue-plyr
import VuePlyr from "@skjnldsv/vue-plyr"
import "@skjnldsv/vue-plyr/dist/vue-plyr.css"
import { cx } from "cva"

const breakpoints = useBreakpoints({ xl: 1200 })
const xlAndLarger  = breakpoints.greaterOrEqual("xl")

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  }
})

const activeMedia = ref<DeviceMedia | null>(null)
const activeMediaIndex = ref(0)
const isLoadingActiveMedia = ref(true)

const displayedMediaCount = ref(8)
const showMore = () => displayedMediaCount.value = combinedMedia.value.length

const videos = computed(() => props.device.Videos.map(video => video.Url))
const webPhotos = computed(() => props.device.WebpPhotos.map(webPhoto => webPhoto.Url))
const photos = computed(() => props.device.Photos.map(photo => photo.Url))
const isVideos = computed(() => videos.value.length > 0)

const isSupportsWebP = computed(() => {
  const elem = document.createElement("canvas")

  if (elem.getContext && elem.getContext("2d")) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0
  }

  return false
})

const combinedMedia = computed(() => {
  const mediaVideos = videos.value.map(url => ({ type: "video", url } as DeviceMedia))
  const mediaPhotos = isSupportsWebP.value
    ? webPhotos.value.map(url => ({ type: "image", url } as DeviceMedia))
    : photos.value.map(url => ({ type: "image", url }) as DeviceMedia)

  return concat(mediaVideos, mediaPhotos)
})

const combinedMediaMob = computed(() => {
  const mediaVideos = videos.value.map(url => ({ type: "video", url } as DeviceMedia))
  const mediaPhotos = isSupportsWebP.value
    ? webPhotos.value.map(url => ({ type: "image", url } as DeviceMedia))
    : photos.value.map(url => ({ type: "image", url } as DeviceMedia))

  if(mediaVideos.length > 0){
    mediaPhotos[0].video = mediaVideos[0]
  }

  return mediaPhotos
})

watch(combinedMedia, (newCombinedMedia) => {
  activeMedia.value = newCombinedMedia[0] as DeviceMedia
})

watch(activeMedia, () => {
  if (activeMedia.value?.type === "image") {
    stopVideo()
    loadActiveMediaImage()
  } else {
    loadActiveMediaVideo()
  }
})

const loadActiveMediaImage = () => {
  if (activeMedia.value?.type !== "image") return

  isLoadingActiveMedia.value = true

  const image = new Image()

  image.src = activeMedia.value.url

  image.onload = () => {
    isLoadingActiveMedia.value = false
  }
}

const loadActiveMediaVideo = () => {
  if (activeMedia.value?.type !== "video") return

  videoPlayer.value?.load()
}

const videoPlayer = ref<HTMLVideoElement | null>(null)
const stopVideo = () => {
  if (isArray(videoPlayer.value)) {
    videoPlayer.value.forEach(video => {
      video.pause()
    })

    return
  }

  if(videoPlayer.value){
    videoPlayer.value.pause()
  }
}

onBeforeMount(() => {
  activeMedia.value = combinedMedia.value[0] as DeviceMedia
})

onMounted(() => {
  if (videoPlayer.value) {
    videoPlayer.value.addEventListener("play", () => {
      emitMixpanelEventCardVideoPlayed(props.device)
      emitYandexMetrika(YANDEX_METRIKA_GOALS.WATCH_VIDEO)
    })
  }
})
</script>

<template>
  <div
    v-if="device"
    id="gallery"
  >
    <div v-if="activeMedia?.type === 'image' && isLoadingActiveMedia">
      <div
        v-if="xlAndLarger"
        :class="classes.root"
      >
        <div :class="[classes.skeletonPanel, skeletonStyles]" />
        <div :class="[classes.skeletonImage, skeletonStyles]" />
      </div>
      <div
        v-else
        class="pr-4"
      >
        <div :class="[classes.skeletonMobileImage, skeletonStyles, 'mb-6']" />
      </div>
    </div>
    <div
      v-else
      :class="classes.root"
    >
      <div :class="classes.pagination">
        <div>
          <div
            v-for="(media, index) in combinedMedia"
            v-show="index + 1 <= displayedMediaCount"
            :key="'miniature-' + index"
            :class="[classes.slide, activeMediaIndex === index ? classes.slideActive : '']"
            @click="activeMedia = media; activeMediaIndex = index"
          >
            <img
              v-show="media.type === 'image'"
              :class="classes.photo"
              :src="media.url"
              loading="lazy"
              :alt="device.FullName"
            >
            <img
              v-show="media.type === 'video'"
              :class="classes.photo"
              src="/images/video-thumbnail.jpg"
              loading="lazy"
              :alt="device.FullName"
            >
          </div>
          <Button
            v-if="!(combinedMedia.length === displayedMediaCount)"
            :class="[linkStyles, linkUnderlineStyles, classes.button]"
            @click="showMore"
          >
            Еще {{ combinedMedia.length - displayedMediaCount }}
          </Button>
        </div>
      </div>
      <div
        v-if="xlAndLarger"
        :class="classes.wrapper"
      >
        <div
          v-show="activeMedia && activeMedia.type === 'video'"
          :class="classes.selectedMedia"
        >
          <vue-plyr
            :options="{
              controls: ['play', 'play-large', 'mute', 'volume', 'fullscreen']
            }"
          >
            <video
              ref="videoPlayer"
              controls
              playsinline
            >
              <source
                :src="activeMedia?.url"
                type="video/mp4"
              >
            </video>
          </vue-plyr>
        </div>
        <MagnifierImage
          v-show="activeMedia?.type === 'image'"
          :image-url="activeMedia?.url"
          :image-class-name="cx(classes.selectedMedia)"
        />
        <Stoppers
          :device="device"
          :class="classes.stoppers"
        />
        <div :class="classes.icons">
          <ToggleFavouriteDevice :device="device" />
          <ToggleComparableDevice :device="device" />
        </div>
      </div>
      <CatalogDeviceMediaGalleryMobileSlider
        v-else
        :device="device"
        :media="combinedMediaMob"
        :class="classes.wrapper"
      />
      <Stoppers
        :class="[classes.stoppers, 'xl:hidden']"
        :device="device"
        :style="{ top : isVideos ? '364px' : '12px' }"
      />
    </div>
  </div>
</template>

<style>
#gallery .plyr {
  width: 100%;
  height: 100%;
}

#gallery video {
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 12px;
}

#gallery button.plyr__control--overlaid {
  background-color: var(--plyr-color-main);
}
</style>
