<script setup lang="ts">
import classes from "./classes"
import { shallowRef } from "vue"
import { sectionStyles } from "shared/lib"
import { Button, Icon } from "shared/ui"
import { EMIT_MODAL_FUNCTION_NAMES, ROUTE_NAMES } from "shared/constants"

const list = shallowRef([
  {
    title: "Подписка выгоднее кредита и рассрочки",
    text: "Выбирая вариант «По подписке» вы получаете то же устройство, но с экономией 40-50% по сравнению с кредитом или рассрочкой.",
    image: {
      url: "/images/section-subscription-description-use-v3/bg-devices",
      width: "261",
      height: "83",
      alt: "Подписка выгоднее кредита и рассрочки"
    },
    button: {
      text: "Как устроена подписка",
      icon: "common/hand-ok",
      iconWidth: "21",
      iconHeight: "21",
      linkName: ROUTE_NAMES.SUBSCRIPTION_DESCRIPTION
    }
  },
  {
    title: "Гарантия на весь срок подписки",
    text: "Все устройства проходят проверку. Будьте уверены в исправности устройства и надежности сервиса в течение всего срока подписки.",
    image: {
      url: "/images/section-subscription-description-use-v3/bg-garanty",
      width: "261",
      height: "83",
      alt: "Гарантия на весь срок подписки"
    },
    button: {
      text: "Подробнее о гарантии",
      icon: "common/shield-check",
      iconWidth: "20",
      iconHeight: "21",
      linkName: ROUTE_NAMES.GARANTIA
    },
    hasModalShowBenefitsChecks: true
  },
  {
    title: "Договор онлайн, доставка бесплатно",
    text: "Заполните заявку онлайн, в случае одобрения мы привезём смартфон бесплатно в выбранный пункт в течение 2-3 дней.",
    image: {
      url: "/images/section-subscription-description-use-v3/bg-order",
      width: "261",
      height: "133",
      alt: "Договор онлайн, доставка бесплатно"
    }
  },
  {
    title: "В конце срока подписки вы сможете",
    text: "<p class='xl:mb-1'><b>Обменять</b> смартфон на новый и перезаключить договор." +
      "<p class='xl:mb-1'><b>Вернуть</b> смартфон и выйти из программы.</p>" +
      "<p><b>Выкупить</b> смартфон.</p>",
    image: {
      url: "/images/section-subscription-description-use-v3/bg-list",
      width: "261",
      height: "121",
      alt: "В конце срока подписки вы сможете"
    }
  }
])

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_BENEFITS_CHECKS
])
</script>

<template>
  <div :class="[sectionStyles, 'container', '!mt-9 xl:!mt-8']">
    <div :class="classes.list">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="classes.item"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          :class="classes.itemTitle"
          v-html="item.title"
        />
        <div
          :class="classes.itemText"
          v-html="item.text"
        />
        <!--eslint-enable-->
        <div class="mt-auto">
          <Button
            v-if="item.hasModalShowBenefitsChecks"
            class="flex items-center font-bold mt-2 mb-4 xl:mb-[18px] whitespace-nowrap text-[14px]/[120%]"
            @click="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_BENEFITS_CHECKS)"
          >
            Все устройства проходят проверку
            <Icon
              name="common/question"
              width="16"
              height="15"
              class-name="ml-1"
            />
          </Button>
          <Button
            v-if="item.button"
            type="router-link"
            intent="base-secondary"
            size="small"
            class="mb-[18px]"
            :url="{ name: item.button.linkName }"
            target="_blank"
          >
            <Icon
              :name="item.button.icon"
              :width="item.button.iconWidth"
              :height="item.button.iconHeight"
              class-name="mr-2.5"
            />
            {{ item.button.text }}
            <Icon
              name="common/chevron"
              width="6"
              height="11"
              class-name="ml-2.5 text-disable -rotate-90"
            />
          </Button>
          <picture
            v-if="item.image"
            :class="[classes.itemImage, index === 2 ? 'w-3/4' : '']"
          >
            <source
              type="image/webp"
              :srcset="`${item.image.url}.webp`"
              :width="item.image.width"
              :height="item.image.height"
            >
            <img
              :src="`${item.image.url}.png`"
              :width="item.image.width"
              :height="item.image.height"
              :alt="item.image.alt"
            >
          </picture>
        </div>
      </div>
    </div>
  </div>
</template>
