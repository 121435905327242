export const DEFAULT_CURRENT_CITY = "Москва"
export const ROUTE_NAMES = {
  HOME: "HomePage",
  CATALOG_CATEGORIES: "CatalogCategoriesPage",
  CATALOG_MODEL_GROUP: "CatalogModelGroupPage",
  CATALOG_MODEL: "CatalogModelPage",
  CATALOG_DEVICE: "CatalogDevicePage",
  CART: "CartPage",
  CONTACTS: "ContactsPage",
  COMPANY: "CompanyPage",
  COMPARISON: "ComparisonPage",
  FAVOURITE: "FavouritePage",
  NOT_FOUND: "NotFoundPage",
  THANKS: "ThanksPage",
  TRADE_IN: "TradeInPage",
  GUARANTEE: "GuaranteePage",
  GARANTIA: "GarantiaPage",
  COMPANY_DETAILS: "CompanyDetailsPage",
  PAYMENT: "PaymentPage",
  GARANTIA_SERVICE: "GarantiaServicePage",
  RETURN: "ReturnPage",
  ACCOUNT: "AccountPage",
  SUBSCRIPTION: "SubscriptionPage",
  SUBSCRIPTION_FORM: "SubscriptionFormPage",
  SUBSCRIPTION_FORM_FILLED: "SubscriptionFormFilledPage",
  SUBSCRIPTION_DESCRIPTION: "SubscriptionDescriptionPage",
  SUBSCRIPTION_THANKS: "SubscriptionThanksPage",
  SUBSCRIPTION_OUT_OF_STOCK: "SubscriptionOutOfStock",
  SEARCH: "SearchPage",
  REDIRECT: "RedirectPage",
  PAYMENT_REDIRECT: "PaymentRedirect",
  ADVANCED_PAYMENT_REDIRECT: "AdvancedPaymentRedirect",
  SIGNING: "SigningRedirectPage",
  REVIEWS: "ReviewsPage",
  CONSENT_DOCUMENT: "ConsentDocumentPage",
  PARTNER_CREDENTIALS: "PartnerCredentialsPage"
}

export const CATALOG_URL_DATA_KEYS = {
  MODEL_GROUP_SLUG: "c_model_group_slug",
  MODEL_SLUG: "c_model_slug",
  MODELS_SLUG: "c_models_slug",
  CONDITION_SLUGS: "c_condition_slugs",
  ACCUMULATOR_SLUGS: "c_accumulator_slugs",
  COLOR_SLUGS: "c_color_slugs",
  MEMORY_SLUGS: "c_memory_slugs",
  DEVICE_FEATURE: "c_device_feature",
  SORT_BY: "c_sort_by",
  MIN_PRICE: "c_min_price",
  MAX_PRICE: "c_max_price",
  SUBSCRIPTION: "c_subscription",
  PAGE: "c_page"
} as const

export const CATALOG_SORT_BY_KEYS = {
  CHEAP_FIRST: "cheapFirst",
  POPULAR_FIRST: "popularFirst"
}

export const CATALOG_FILTER_NAMES = {
  SUBSCRIBE: "Подписка",
  MEMORY: "Память",
  COLOR: "Цвет",
  MODEL: "Модель"
}

export const DEVICES_SORT_BY = {
  IS_POPULAR: "IsPopular",
  PRICE: "Price"
}

export const EMIT_MODAL_FUNCTION_NAMES = {
  OPEN_MODAL_SHOW_CITIES: "openModalShowCities",
  OPEN_MODAL_SHOW_SERVICE_CENTER: "openModalShowServiceCenter",
  OPEN_MODAL_SHOW_SERVICE_CENTERS: "openModalShowServiceCenters",
  OPEN_MODAL_SHOW_BENEFITS_CHECKS: "openModalShowBenefitsChecks",
  OPEN_MODAL_SHOW_BENEFITS_CONFIDENCE: "openModalShowBenefitsConfidence",
  OPEN_MODAL_VERIFICATE_PHONE: "openModalVerificatePhone",
  OPEN_MODAL_SHOW_SPECIFICATIONS: "openModalShowSpecifications",
  OPEN_MODAL_SUBSCRIPTION_HOW_WORKS: "openModalSubscriptionHowWorks"
}

export const STOPPER_COLORS = {
  RED: "red",
  GREEN: "green",
  BLUE: "lightblue",
  WHITE: "white",
  YELLOW: "yellow"
} as const

export const PAYMENT_METHODS_KEYS = {
  ONLINE: "Online",
  DELIVERY: "OnDelivery"
}

export const PHONE_VERIFICATION_CONTEXT = {
  ORDER: "order",
  SUBSCRIPTION_ORDER: "subscriptionOrder"
}

export const MODEL_GROUP_NAME = {
  IPHONE: "iPhone",
  IPHONE_11: "iPhone 11",
  IPHONE_12: "iPhone 12",
  SAMSUNG: "Samsung",
  HUAWEI: "Huawei",
  XIAOMI: "Xiaomi"
}

export const DEVICE_PROPERTY_KEYS = {
  STATE: "state",
  BATTERY_CAPACITY: "battery capacity",
  APPLE_ID: "apple id",
  GARANTEE: "garantee",
  IMEI: "imei",
  VERSION: "version",
  COLOR: "color",
  MEMORY: "memory",
  SIM: "sim",
  ACCESSORIES: "accessories",
  NFC: "nfc"
}

export const DEFAULT_ISO_DATE = "0001-01-01T00:00:00+00:00"
