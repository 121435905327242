export let juicyScoreSessionId: string | null = null
export let juicyScoreCompletedSessionId: string | null = null

export const juicyScoreService = {
  sessionId: null,

  setup() {
    return new Promise((resolve, reject) => {
      window.juicyLabConfig = { apiKey: import.meta.env.APP_JUICY_SCORE_API_KEY }

      const script = document.createElement('script')
      script.src = import.meta.env.APP_JUICY_SCORE_SCRIPT_URL
      script.async = true
      script.onload = resolve
      script.onerror = reject
      document.head.appendChild(script)

      window.addEventListener('load', async function () {
        juicyScoreSessionId = await window.juicyScoreApi.getSessionId()
        juicyScoreCompletedSessionId = await window.juicyScoreApi.getCompletedSessionId()
      })
    })
  },
  submitForm: () => {
    if (window.jslabApi?.manuallyComplete) {
      window.jslabApi.manuallyComplete.dispatchEvent(new Event('click'))
    }
  }
}
