import { cx } from "cva"

const root = cx([
  "w-full",
  "text-[14px]",
  "xl:text-[16px]",
  "xl:mt-0"
])

const mainContent = cx([
  "mb-[30px]",
  "xl:mb-0"
])

const title = cx([
  "text-[24px]",
  "font-bold",
  "text-primary",
  "mb-6",
  "text-center",
  "xl:text-start"
])

const filtersInputs = cx([
  "grid",
  "gap-2",
  "mb-4",
  "xl:mb-[30px]"
])

const item = cx([
  "mb-[25px]"
])

const itemTitle = cx([
  "cursor-pointer",
  "flex",
  "items-center",
  "text-[16px]/[1]",
  "font-bold",
  "text-primary",
  "mb-2.5"
])

const itemTitleArrow = cx([
  "ml-[13px]",
  "transition-transform",
  "duration-300",
  "rotate-180"
])

const itemTitleArrowRotate = cx([
  "!rotate-0"
])

const listItem = cx([
  "leading-none",
  "mb-[9px]",
  "last:mb-0"
])

const listItemColors = cx([
  "w-4",
  "h-4",
  "mr-2",
  "border",
  "border-solid",
  "border-[#E7E6E6]",
  "rounded-[50%]"
])

const checkbox = cx([
  "flex",
  "items-center"
])

const checkboxInput = cx([
  "absolute",
  "z-[-1]",
  "opacity-0",
  "peer"
])

const checkboxLabel = cx([
  "text-primary",
  "inline-flex",
  "items-center",
  "select-none",
  "before:inline-block",
  "before:w-[18px]",
  "before:h-[18px]",
  "before:border-2",
  "before:border-solid",
  "before:border-primary",
  "before:bg-bg-main-primary",
  "before:rounded",
  "before:mr-2",
  "before:bg-no-repeat",
  "before:bg-center",
  "peer-checked:before:bg-bg-blue-accent",
  "peer-checked:before:border-border-active",
  "peer-checked:before:bg-[url('/images/checkmark2.svg')]"
])

const mobileActionsButton = cx([
  "text-accent",
  "text-sm",
  "mb-2.5",
  "xl:hidden"
])

const filterSkeleton = cx([
  "flex",
  "items-center",
  "my-3"
])

const filterSkeletonCheckbox = cx([
  "h-5",
  "w-5",
  "rounded",
  "mr-2",
  "animate-pulse",
  "bg-slate-200"
])

const filterSkeletonColor = cx([
  "h-[12px]",
  "w-[12px]",
  "rounded-full",
  "mr-3",
  "animate-pulse",
  "bg-slate-200"
])

const filterSkeletonValue = cx([
  "h-2",
  "w-full",
  "rounded",
  "animate-pulse",
  "bg-slate-200"
])

export default {
  root,
  mainContent,
  title,
  filtersInputs,
  item,
  itemTitle,
  itemTitleArrow,
  itemTitleArrowRotate,
  listItem,
  listItemColors,
  checkbox,
  checkboxInput,
  checkboxLabel,
  mobileActionsButton,
  filterSkeleton,
  filterSkeletonCheckbox,
  filterSkeletonColor,
  filterSkeletonValue
}
