const root = [
  "bg-gradient-banner",
  "cursor-pointer",
  "transition",
  "duration-300",
  "hover:opacity-90"
]
const wrapper = [
  "bg-[url('/images/banner/mob-l.png'),_url('/images/banner/mob-r.png')]",
  "xl:bg-[url('/images/banner/desk-l.png'),_url('/images/banner/desk-r.png')]",
  "bg-[position:left_bottom,_right_bottom]",
  "bg-no-repeat"
]
const inner = [
  "container",
  "grid",
  "grid-rows-[repeat(2,auto)]",
  "xl:grid-rows-none",
  "xl:grid-cols-[repeat(2,min-content)]",
  "xl:justify-center",
  "xl:gap-[24px]",
  "place-items-center",
  "py-[10px]",
  "px-[16px]",
  "xl:py-[7px]"
]
const ul = [
  "grid",
  "grid-cols-[repeat(3,min-content)]",
  "gap-[16px]",
  "mb-[6px]",
  "xl:mb-[0px]"
]
const li = [
  "text-secondary-on-color",
  "whitespace-nowrap",
  "grid",
  "grid-cols-[5px_1fr]",
  "items-center",
  "gap-[7px]",
  "text-[11px]",
  "xxs:text-[14px]",
]

const buttonWrapper = [
  "flex",
  "items-center"
]

const button = [
  "w-max",
  "pt-[4px]",
  "pb-[4px]"
]

const iconWrapper = [
  "px-2",
  "py-1",
  "flex",
  "bg-snow-white-500",
  "rounded-r-lg",
  "-ml-2"
]

export default {
  root,
  buttonWrapper,
  button,
  iconWrapper,
  wrapper,
  inner,
  ul,
  li
}
